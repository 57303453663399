<template>
  <section class="banner" data-scroll-index="0" id="main">
    <div class="banner-overlay">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-12">
            <div class="banner-text">
              <h1 class="banertext">
                Лучший выбор для вашего здорового образа жизни
              </h1>
              <h5 class="banertext">
                Частное Производственное предприятие «BioMed Industry» в
                настоящее время выпускает более 25 наименований препаратов.
                Среди биопрепаратов важное место занимают пробиотики. Компания
                наладила отдельную линию культивирования микроорганизмов по
                новым технологиям, соответствующей стандартам качества,
                совместно с иностранными учёными из США.
              </h5>
              <!-- <ul>
                <li>
                  <a href="#"
                    ><img
                      src="./images/appstore.png"
                      class="wow fadeInUp"
                      data-wow-delay="0.4s"
                  /></a>
                </li>
                <li>
                  <a href="#"
                    ><img
                      src="./images/playstore.png"
                      class="wow fadeInUp"
                      data-wow-delay="0.7s"
                  /></a>
                </li>
              </ul> -->
            </div>
          </div>
          <!-- <div class="col-md-4 col-sm-12">
            <img
              src="@/images/Bifidobacterium_bifi.png"
              class="img-fluid wow fadeInUp head_baner_img"
            />
          </div> -->
        </div>
      </div>
    </div>
    <!-- <span class="svg-wave">
      <img class="svg-hero" src="./images/applight-wave.svg" />
    </span> -->
  </section>
</template>

<style src='../css/style.css'></style>
