<template>
  <section class="team section-padding" data-scroll-index="2" id="products">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="sectioner-header text-center">
            <h2 class="text-h4 text-lg-h3">
              Ознакомьтесь с нашими штаммами микроорганизмов
            </h2>
            <span class="line"></span>
            <p>
              «Лаборатория производства пробиотиков» BioMed Industry предлагает
              биопрепараты (биомассы) высокой концентрации и жизнеспособности на
              основе метода глубинной ферментации.
            </p>
          </div>
          <div class="section-content text-center">
            <div class="row">
              <div class="col-md-3" v-for="(item, n) in items" :key="n">
                <div
                  class="team-detail wow bounce"
                  style="cursor: pointer"
                  data-wow-delay="0.2s"
                  @click="showDialog(item)"
                >
                  <img
                    :src="require(`@/images/products/${item.src}`)"
                    class="img-fluid"
                  />
                  <h4>{{ item.name }}</h4>
                  <!-- <p>Marketing Specialist</p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <v-dialog v-model="dialog" width="1200">
    <v-card max-width="1200">
      <v-card-item>
        <v-card-title class="text-center">
          <h1>{{ chosenProduct.name }}</h1></v-card-title
        >
        <v-card-subtitle> {{ chosenProduct.subtitle }} </v-card-subtitle>
      </v-card-item>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <!-- <h4>Описание</h4>
            <h6>
              Пробиотический порошок BB состоит из штамма Bifidobacterium
              bifidum. B. bifidum — одна из наиболее распространенных
              пробиотических бактерий, встречающихся в организме млекопитающих,
              включая человека.
            </h6> -->
            <div class="text-center">
              <!-- src="@/images/Bifidobacterium_bifi.png" -->
              <img
                :src="require(`@/images/products/${chosenProduct.img}`)"
                class="img-fluid card_img center"
              />
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <table class="table table-striped">
              <tbody>
                <tr v-for="(item, index) in chosenProduct.short" :key="index">
                  <td>{{ item.title }} : {{ item.item }}</td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
        <div class="pt-4">
          <v-tabs v-model="tab" bg-color="primary">
            <v-tab value="one">Описание</v-tab>
            <v-tab value="two">Польза для здоровья</v-tab>
            <v-tab value="three">Технические показатели</v-tab>
          </v-tabs>
          <v-window v-model="tab">
            <v-window-item value="one">
              <div class="pt-3">
                <h6>
                  {{ chosenProduct.opisaniya }}
                </h6>
              </div>
            </v-window-item>

            <v-window-item value="two">
              <div class="pt-3">
                <ol class="list-group list-group-numbered list-group-flush">
                  <li
                    class="list-group-item"
                    v-for="(item, n) in chosenProduct.polza"
                    :key="n"
                  >
                    {{ item }}
                  </li>
                </ol>
              </div>
            </v-window-item>

            <v-window-item value="three">
              <div class="pt-3">
                <h5 class="text-bold">Ингридиенты</h5>
                <p>{{ chosenProduct.ingredient }}</p>
                <h5 class="text-bold">Стандартная спецификация</h5>
                <p>{{ chosenProduct.spesific }}</p>
                <h5 class="text-bold">Срок годности</h5>
                <p>{{ chosenProduct.srok }}</p>
                <h5 class="text-bold">Технические показатели</h5>

                <!-- TABLE -->
                <div class="table-responsive">
                  <table
                    class="table table-bordered table-sm"
                  >
                    <thead class="table-light">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Показатели</th>
                        <th scope="col">Значение</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in chosenProduct.texnik"
                        :key="index"
                      >
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ item.title }}</td>
                        <td>{{ item.item }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h5 class="text-bold">Антогистическая активность:</h5>
                <p>{{ chosenProduct.aktivnost }}</p>
              </div>
            </v-window-item>
          </v-window>
        </div>
      </v-card-text>
      <hr />
      <v-card-actions class="justify-center">
        <v-btn variant="tonal" @click="dialog = false">ЗАКРЫТЬ</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import { mapState } from "vuex";
import baseProducts from "../assets/product.json";
export default {
  data: () => ({
    tab: null,
    products: baseProducts,
    chosenProduct: null,
    dialog: false,
    show_detail: false,
    card_title: "",
    card_text: "",
    items: [
      {
        src: "Bifidobacterium_bifi.png",
        name: "Bifidobacterium bifidum",
        text: "Описание Пробиотический порошок BB состоит из штамма Bifidobacterium bifidum. B. bifidum — одна из наиболее распространенных пробиотических бактерий, встречающихся в организме млекопитающих, включая человека. B. bifidum — незаменимая бактерия, обитающая в кишечнике человека. Когда его низкий уровень или он вообще отсутствует в кишечнике человека, это признак нездорового состояния. Кишечную флору можно улучшить, если принимать перорально B. bifidum. Кроме того, пероральный B. bifidum используется для других целей, таких как терапия кишечных и печеночных заболеваний, для активации иммунного ответа и для предотвращения некоторых видов рака. Количество B. bifidum уменьшается с возрастом. По мере уменьшения количества B. bifidum увеличивается количество других кишечных бактерий, таких как лактобактерии, энтерококки, энтеробактерии и клостридии. Все это увеличивает риск развития рака у пожилых людей и снижает способность их печени функционировать адекватно и эффективно.",
      },
      {
        src: "Bifidobacterium_lact.png",
        name: "Bifidobacterium lactis",
      },
      {
        src: "Bifidobacterium_long.png",
        name: "Bifidobacterium longum",
      },
      {
        src: "Bacillus_coagulans_L.png",
        name: "Bacillus subtilis",
      },
      // 2 qator
      {
        // ---
        src: "Bifidobacterium_brev.png",
        name: "Lactobacillus bulgaricus",
      },
      {
        src: "Enterococcus_faecium.png",
        name: "Enterococcus faecium",
      },
      {
        // ---
        src: "Lactococcus_lactis_L.png",
        name: "Lactobacillus casei",
      },
      {
        src: "Lactobacillus_fermen.png",
        name: "Lactobacillus fermentum",
      },
      // 3 qator
      {
        src: "Lactobacillus_paraca.png",
        name: "Lactobacillus paracasei",
      },
      {
        src: "Lactobacillus_rhamno.png",
        name: "Lactobacillus rhamnosus",
      },
      {
        src: "Lactobacillus_planta.png",
        name: "Lactobacillus plantarum",
      },
      {
        // --
        src: "noroot.png",
        name: "Lactobacillus acidophilus",
      },
      // 4 qator
      {
        // --
        src: "Lactobacillus_helvet.png",
        name: "Lacticaseibacillus zeae",
      },

      {
        src: "soon_with_text.png",
        name: "Bifidobacterium aldolescentis",
      },
      {
        src: "soon_with_text.png",
        name: "Bifidobacterium breve",
      },
      {
        src: "soon_with_text.png",
        name: "Lactobacillus gallinarum",
      },
      // 5 - qator
      {
        src: "soon_with_text.png",
        name: "Lactobacillus helveticus",
      },
      {
        src: "soon_with_text.png",
        name: "Lactobacillus reuteri",
      },
      {
        src: "soon_with_text.png",
        name: "Lactococcus lactis",
      },
      {
        src: "soon_with_text.png",
        name: "Leuconostoc mesenteroides",
      },
      // 6 -qator
      {
        src: "soon_with_text.png",
        name: "Peopionbacterium freudenreichii",
      },
      {
        src: "soon_with_text.png",
        name: "Streptococcus salivarus subsp. thermophilus",
      },
      // {
      //   src: "noroot.png",
      //   name: "Noroot",
      // },
      // 7 - qator
      // {
      //   src: "Saccharomyces_boular.png",
      //   name: "Saccharomyces boular",
      // },
      // {
      //   src: "Streptococcus_thermo.png",
      //   name: "Streptococcus thermo",
      // },
    ],
    shtam_vid: [
      {
        title: "Домен",
        value: "Bacteria",
      },
      {
        title: "Тип",
        value: "Actinomycetota",
      },
      {
        title: "Класс",
        value: "Actinomycetia",
      },
      {
        title: "Отряд",
        value: "Actinomycetia",
      },
      {
        title: "Отряд",
        value: "Actinomycetia",
      },
      {
        title: "Отряд",
        value: "Actinomycetia",
      },
      {
        title: "Отряд",
        value: "Actinomycetia",
      },
    ],
    selection: [],
  }),
  computed: {
    // ...mapState(["products"]),
  },
  methods: {
    // ...mapGetters(["getProductByName"]),

    showDialog(item) {
      console.log(item)
      if (item.src !== "soon_with_text.png")
      {
        this.choose_Product(item.name);
        this.dialog = true;
      }
      else{
        console.log("pass")
      }
    },

    choose_Product(name) {
      // let chosenProduct = null;
      this.products.forEach((product) => {
        if (product.name === name) {
          this.chosenProduct = product;
          return;
        }
      });

      if (this.chosenProduct) {
        // Agar tanlangan mahsulot topilsa, uni ishlatishingiz mumkin
        console.log("Tanlangan mahsulot:", this.chosenProduct);
        
      } else {
        console.log("Mahsulot topilmadi");
      }
    },
  },
};
</script>

<style scoped>
.card_img {
  max-height: 300px;
}
</style>