<template>
    <section data-scroll-index="1" id="proizvodsvo">
        <v-container class="pt-15" >
          <div class="pa-md-10">
            <v-row class="">
              <v-col cols="12" md="6" sm="12">
                <div class="d-flex flex-column fill-height justify-center align-left">
                  <h1 class="text-lg-h3 text-h4  mb-3">Как мы производим</h1>
                  <h1 class="text-lg-h3 text-h4 mb-4">Пробиотики</h1>
                  <h4 style="font-weight: lighter;" class="text-h6">
                    Наша компания использует новые современные технологии при
                    производстве пробиотической смеси. В ходе производства создается
                    поливалентная бактериальная масса на основе 12 различных штаммов,
                    имеющихся в нашей коллекции. «Лаборатория производства
                    пробиотиков» BioMed Industry предлагает биопрепараты (биомассы)
                    высокой концентрации и жизнеспособности на основе метода глубинной
                    ферментации.
                  </h4>
                  <!-- <v-btn width="200" class="mt-5"> Читать далее </v-btn> -->
                  <a href="#" class="about-btn" style="width: 200px;">Читать далее</a>
                </div>
              </v-col>
              <v-col  cols="12" md="6" sm="12">
                <v-img
                  :src="require('@/images/pic-2.jpg')"
                  height="500px"
                  class="head_baner_img"
                  aspect-ratio="4/3"
                ></v-img>
              </v-col>
            </v-row>
          </div>
        </v-container>
    </section>
</template>


<style src='../css/style.css'>
</style>
